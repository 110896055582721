<template>
  <div class="pageWrapper">
    <div class="topbar">
      <div style="display: flex">
        <H2>Stores</H2>
      </div>
      <MainButton
        :clickFunction="openAddShop"
        title="Add store"
        styleClass="primary"
      />
    </div>
    <table class="standardTable shopsTable">
      <br />
      <tr class="standardTableHeader">
        <th>Strore name</th>
        <th v-if="!isMobile"></th>
        <th v-if="!isMobile"></th>
      </tr>
      <LoadingRowTable :dataList="shops" :columnsAmount="3">
        <StoreRowItem
          :key="shop.id"
          :shop="shop"
          v-for="shop in shops"
        />
      </LoadingRowTable>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import StoreRowItem from '@/components/stores/StoreRowItem'
import LoadingRowTable from '@/components/LoadingRowTable'
import MainButton from '@/layout/MainButton'
import AddStorePopupBox from '@/components/stores/AddStorePopupBox'
import H2 from '@/layout/typography/H2'

export default {
  components: { StoreRowItem, LoadingRowTable, MainButton, H2 },
  data() {
    return {
      shops: undefined,
      shopName: undefined,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
  },
  created() {
    this.getShops()
  },
  methods: {
    openAddShop() {
      this.$store.commit('SET_POPUP', { component: AddStorePopupBox, properties: { getShops: this.getShops } })
    },
    getShops() {
      axios.get('storage/getCompanyShops').then((response) => {
        this.shops = response.data
      })
    },
  },
}
</script>

<style scoped lang="scss">
.addShopWrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.addShop span input:focus {
  border-bottom: 1px solid rgb(139, 176, 255);
}

.addShop span input {
  font-size: 8pt;
  position: relative;
  border: none;
  border-bottom: 1px solid lightgray;
  transition: 0.2s ease-in-out;
}

.addShop span {
  display: inline-flex;
  position: relative;
  margin: 10px;
  padding: 10px;
}

.addShop {
  background: white;
  position: relative;
  width: 50%;
  transition: 0.2s ease-in-out;
  display: inline-flex;
  border-radius: 7px;
  height: 0px;
  display: block;
  overflow: hidden;
  margin: 10px 0;
}
.button {
  border: none;
  background: rgb(24, 185, 51);
  color: white;
  border-radius: 3px;
  font-family: 'AvenirRound', sans-serif;
  padding: 5px 7px;
  border: 1px solid rgb(24, 185, 51);
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 700px) {
  .shopsTable {
    table-layout: auto;
  }
}
</style>