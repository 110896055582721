<template>
  <tr @click="openShop(shop.id)" style="cursor:pointer;" class="tableRow">
    <td>{{ shopName }}</td>
    <td v-if="!isMobile"></td>
    <td v-if="!isMobile"></td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      storageLocation: null,
      shopName: this.shop.locationName
    }
  },
  props: {
    shop: Object,
  },

  methods: {
    openShop(id) {
      this.$router.push({ name: 'Store', params: { storeId: id } })
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isMobile: 'isMobile',
    }),
  },
}
</script>

<style scoped lang="scss">
.editInput:focus {
  border-bottom: 1px solid rgb(102, 170, 235);
}
.editInput {
  border:none;
  font-size: 10pt;
  color: rgb(77, 77, 77);
  transition: .2s ease-in-out;
  border-bottom: 1px solid gray;
}

ion-icon:hover {
  color: rgb(77, 139, 255);
  font-weight: bold;
}
ion-icon {
  transition: 0.2s ease-in-out;
  font-size: 13pt;
  cursor: pointer;
  text-align: center;
}

</style>